import {
  ADDSTUDENT, DELETESTUDENT, EDITSTUDENT, SAVESTUDENT, ADDROLE, SAVEROLE, DELETEROLE
} from "../constant/login";
import { StudentList, RoleList } from "../../types";
import { STUDENTACTION, ROLEACTION } from '../action/login';

const studentReducer = (state: StudentList = { list: [] }, action: STUDENTACTION): StudentList => {
  switch (action.type) {
    case ADDSTUDENT:
      return {
        list: state.list.concat({ id: state.list.length, name: action.payload.name || '', age: action.payload.age || 0, edit: false })
      }
    case DELETESTUDENT:
      return {
        list: state.list.filter(item => item.id !== action.payload.id).map((item, index) => {
          return {
            ...item,
            id: index
          }
        })
      }
    case EDITSTUDENT:
      return {
        list: state.list.map(item => {
          if (item.id === action.payload.id) {
            return { ...item, edit: !item.edit }
          } else {
            return {
              ...item,
              edit: false
            }
          }
        })
      }
    case SAVESTUDENT:
      return {
        list: state.list.map(item => {
          if (item.id === action.payload.id) {
            return {
              ...item,
              name: action.payload.name || '',
              age: action.payload.age || 0,
              edit: false
            }
          } else {
            return item
          }
        })
      }
    default:
      return state;
  }
}

const roleReducer = (state: RoleList = { list: [] }, action: ROLEACTION): RoleList => {
  switch (action.type) {
    case ADDROLE:
      return {
        list: state.list.concat({
          id: state.list.length,
          roleName: action.payload.roleName || '',
          remark: action.payload.remark || '',
          gmtCreate: action.payload.gmtCreate || ''
        })
      }
    case SAVEROLE:
      return {
        list: state.list.map(item => ({
          id: item.id,
          roleName: item.id === action.payload.id ? (action.payload.roleName || '') : item.roleName,
          remark: item.id === action.payload.id ? (action.payload.remark || '') : item.remark,
          gmtCreate: item.id === action.payload.id ? (action.payload.gmtCreate || '') : item.gmtCreate
        }))
      }
    case DELETEROLE:
      return {
        list: state.list.filter(item => item.id !== action.payload.id)
      }
    default:
      return state
  }
}

export { studentReducer, roleReducer }