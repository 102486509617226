import {
  createStore,
  combineReducers,
  compose,
  applyMiddleware
} from 'redux';
import thunk from 'redux-thunk';

import {
  studentReducer,
  roleReducer
} from './reducer/login';
import MenuReducer from './reducer/menu'
//store, pure function as argument
//compose for adding middleware
// thunk is a promise for supporting asynchronous actions
let store = createStore(combineReducers({
  studentReducer,
  roleReducer,
  MenuReducer
}), compose(
  applyMiddleware(thunk),
  window.devToolsExtension ? window.devToolsExtension() : f => f
));

export default store;