import {UPDATE_SELECTMENU} from '../constant/menu'
const INITVALUE = 0

const MenuReducer = (state = INITVALUE, action: { type: string, payload: any }) => {
    switch (action.type) {
      case UPDATE_SELECTMENU:
        return action.payload
      default:
        return state
    }
  }

export default MenuReducer