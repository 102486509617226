
export const ADDSTUDENT = 'ADDSTUDENT';
export const DELETESTUDENT = 'DELETESTUDENT';
export const EDITSTUDENT = 'EDITSTUDENT';
export const SAVESTUDENT = 'SAVESTUDENT';
export type STUDENTTYPE = typeof ADDSTUDENT | typeof DELETESTUDENT | typeof EDITSTUDENT | typeof SAVESTUDENT;

export const ADDROLE = 'ADDROLE';
export const DELETEROLE = 'DELETEROLE';
export const SAVEROLE = 'SAVEROLE';
export type ROLETYPE = typeof ADDROLE | typeof DELETEROLE | typeof SAVEROLE;

export const UPDATE_LOGISIT = 'UPDATE_LOGISIT'
