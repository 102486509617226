import React from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import './assets/css/index.css';
import { exitRouters } from './routes/index';
class App extends React.Component<[], []> {
  render() {
    return (
      <div className="App">
        <React.Suspense fallback={null}>
          <Switch>
            {exitRouters.map((route) => <Route {...route} key={route.path} />)}
          </Switch>
        </React.Suspense>
      </div>
    );
  }
}

export default withRouter(App as any);