import React from 'react';
const Login = React.lazy(() => import(/* webpackChunkName: "login" */ '../views/login/login'))
const Index = React.lazy(() => import(/* webpackChunkName: "system" */ '../views/index'))

//system
const Home = React.lazy(() => import(/* webpackChunkName: "system" */ '../views/system/index'))
const Menu = React.lazy(() => import(/* webpackChunkName: "system" */ '../views/system/menu'))
const Role = React.lazy(() => import(/* webpackChunkName: "system" */ '../views/system/role'))
const RoleModify = React.lazy(() => import(/* webpackChunkName: "system" */ '../views/system/role-modify'))
const Account = React.lazy(() => import(/* webpackChunkName: "system" */ '../views/system/account'))
const AccountModify = React.lazy(() => import(/* webpackChunkName: "system" */ '../views/system/account-modify'))
const Carriage = React.lazy(() => import(/* webpackChunkName: "system" */ '../views/system/carriage'))
const CarriageModify = React.lazy(() => import(/* webpackChunkName: "system" */ '../views/system/carriage-modify'))
const Log = React.lazy(() => import(/* webpackChunkName: "system" */ '../views/system/log'))
const Config = React.lazy(() => import(/* webpackChunkName: "system" */ '../views/system/config'))

// user
const UserUser = React.lazy(() => import(/* webpackChunkName: "user" */ '../views/user/user'))
const Distributor = React.lazy(() => import(/* webpackChunkName: "user" */ '../views/user/distributor'))
const UserDetail = React.lazy(() => import(/* webpackChunkName: "user" */ '../views/user/user-detail'))
const UserBalance = React.lazy(() => import(/* webpackChunkName: "user" */ '../views/user/user-balance'))

// goods
// const GoodsIndex = React.lazy(() => import(/* webpackChunkName: "goods" */ '../views/goods/index'))
// const GoodsDetail = React.lazy(() => import(/* webpackChunkName: "goods" */ '../views/goods/detail'))
// const GoodsModify = React.lazy(() => import(/* webpackChunkName: "goods" */ '../views/goods/modify'))
// const GoodsCreate = React.lazy(() => import(/* webpackChunkName: "goods" */ '../views/goods/create'))
// const GoodsBrand = React.lazy(() => import(/* webpackChunkName: "goods" */ '../views/goods/brand'))
// const GoodsBrandModify = React.lazy(() => import(/* webpackChunkName: "goods" */ '../views/goods/brand-modify'))
// const GoodsClassify = React.lazy(() => import(/* webpackChunkName: "goods" */ '../views/goods/classify'))
// const GoodsClassifyModify = React.lazy(() => import(/* webpackChunkName: "goods" */ '../views/goods/classify-modify'))
// const GoodsClassifyFinal = React.lazy(() => import(/* webpackChunkName: "goods" */ '../views/goods/classify-final'))
// const GoodsClassifyFinalModify = React.lazy(() => import(/* webpackChunkName: "goods" */ '../views/goods/classify-final-modify'))
// const GoodsSupply = React.lazy(() => import(/* webpackChunkName: "goods" */ '../views/goods/supply'))
// const GoodsSupplyAdd = React.lazy(() => import(/* webpackChunkName: "goods" */ '../views/goods/supply-add'))
// const GoodsSupplyDetail = React.lazy(() => import(/* webpackChunkName: "goods" */ '../views/goods/supply-detail'))
// const GoodsCustomer = React.lazy(() => import(/* webpackChunkName: "goods" */ '../views/goods/customer'))
// const GoodsCustomerModify = React.lazy(() => import(/* webpackChunkName: "goods" */ '../views/goods/customer-modify'))
// const GoodsGroup = React.lazy(() => import(/* webpackChunkName: "goods" */ '../views/goods/group'))
// const GoodsGroupModify = React.lazy(() => import(/* webpackChunkName: "goods" */ '../views/goods/group-modify'))
// const GoodsItems = React.lazy(() => import(/* webpackChunkName: "goods" */ '../views/goods/items'))
// const GoodsItemsModify = React.lazy(() => import(/* webpackChunkName: "goods" */ '../views/goods/items-modify'))
// const GoodsProperty = React.lazy(() => import(/* webpackChunkName: "goods" */ '../views/goods/property'))
// const GoodsPropertyInput = React.lazy(() => import(/* webpackChunkName: "goods" */ '../views/goods/property-input'))
// const GoodsPropertySell = React.lazy(() => import(/* webpackChunkName: "goods" */ '../views/goods/property-sell'))

// operate
// const OperateActivity = React.lazy(() => import(/* webpackChunkName: "operate" */ '../views/operate/activity'))
// const OperateActivityModify = React.lazy(() => import(/* webpackChunkName: "operate" */ '../views/operate/activity-modify'))
// const OperateBanner = React.lazy(() => import(/* webpackChunkName: "operate" */ '../views/operate/banner'))
// const OperateBannerModify = React.lazy(() => import(/* webpackChunkName: "operate" */ '../views/operate/banner-modify'))
// const OperateCounpon = React.lazy(() => import(/* webpackChunkName: "operate" */ '../views/operate/coupon'))
// const OperateCounponCreate = React.lazy(() => import(/* webpackChunkName: "operate" */ '../views/operate/coupon-create'))
// const OperateCounponDetail = React.lazy(() => import(/* webpackChunkName: "operate" */ '../views/operate/coupon-detail'))
// const OperateGrant = React.lazy(() => import(/* webpackChunkName: "operate" */ '../views/operate/coupon-grant'))

// order
// const Order = React.lazy(() => import(/* webpackChunkName: "order" */ '../views/order/index'))
// const OrderDetail = React.lazy(() => import(/* webpackChunkName: "order" */ '../views/order/detail'))
// const OrderCross = React.lazy(() => import(/* webpackChunkName: "order" */ '../views/order/cross'))
// const OrderCrossDetail = React.lazy(() => import(/* webpackChunkName: "order" */ '../views/order/cross-detail'))
// const OrderPay = React.lazy(() => import(/* webpackChunkName: "order" */ '../views/order/pay'))
// const OrderRefund = React.lazy(() => import(/* webpackChunkName: "order" */ '../views/order/refund'))
// const OrderRefundDetail = React.lazy(() => import(/* webpackChunkName: "order" */ '../views/order/refund-detail'))
// const OrderCrossServe = React.lazy(() => import(/* webpackChunkName: "order" */ '../views/order/crossServe'))
// const OrderCrossServeDetail = React.lazy(() => import(/* webpackChunkName: "order" */ '../views/order/crossServe-detail'))
// const OrderGroup = React.lazy(() => import(/* webpackChunkName: "order" */ '../views/order/group'))
// const OrderServe = React.lazy(() => import(/* webpackChunkName: "order" */ '../views/order/serve'))
// const OrderServeDetail = React.lazy(() => import(/* webpackChunkName: "order" */ '../views/order/serve-detail'))
// const OrderSupply = React.lazy(() => import(/* webpackChunkName: "order" */ '../views/order/supply'))
// const OrderSupplyDetail = React.lazy(() => import(/* webpackChunkName: "order" */ '../views/order/supply-detail'))
// const OrderSupplyService = React.lazy(() => import(/* webpackChunkName: "order" */ '../views/order/supplyService'))
// const OrderSupplyServiceDetail = React.lazy(() => import(/* webpackChunkName: "order" */ '../views/order/supplyService-detail'))
// const OrderTicket = React.lazy(() => import(/* webpackChunkName: "order" */ '../views/order/ticket'))

// finance
// const FinanceAccount = React.lazy(() => import(/* webpackChunkName: "finance" */ '../views/finance/account'))
// const FinanceAccountDetail = React.lazy(() => import(/* webpackChunkName: "finance" */ '../views/finance/account-detail'))
// const FinanceBalance = React.lazy(() => import(/* webpackChunkName: "finance" */ '../views/finance/balance'))
// const FinanceCash = React.lazy(() => import(/* webpackChunkName: "finance" */ '../views/finance/cash'))
// const FinanceCashDetail = React.lazy(() => import(/* webpackChunkName: "finance" */ '../views/finance/cash-detail'))
// const FinanceGoodSell = React.lazy(() => import(/* webpackChunkName: "finance" */ '../views/finance/goodsell'))
// const FinanceReview = React.lazy(() => import(/* webpackChunkName: "finance" */ '../views/finance/review'))
// const FinanceReviewDetail = React.lazy(() => import(/* webpackChunkName: "finance" */ '../views/finance/review-detail'))

// hospital
const HospitalList = React.lazy(() => import(/* webpackChunkName: "hospital" */ '../views/hospital/hospitalList'))
const HospitalListModify = React.lazy(() => import(/* webpackChunkName: "hospital" */ '../views/hospital/hospital-modify'))

//distribution
const ActivityList = React.lazy(() => import(/* webpackChunkName: "distribution" */ '../views/distribution/activity'))
const ActivityModify = React.lazy(() => import(/* webpackChunkName: "distribution" */ '../views/distribution/activity-modify'))
const ExtensionList = React.lazy(() => import(/* webpackChunkName: "distribution" */ '../views/distribution/extension'))
const DistributorList = React.lazy(() => import(/* webpackChunkName: "distribution" */ '../views/distribution/distributor'))
const FundsManage = React.lazy(() => import(/* webpackChunkName: "distribution" */ '../views/distribution/fundsManage'))
const CommissionManage = React.lazy(() => import(/* webpackChunkName: "distribution" */ '../views/distribution/commission'))
const DistributionOrderList = React.lazy(() => import(/* webpackChunkName: "distribution" */ '../views/distribution/order'))
const DistributionOrderDetail = React.lazy(() => import(/* webpackChunkName: "distribution" */ '../views/distribution/orderDetail'))


const ErrorIndex = React.lazy(() => import(/* webpackChunkName: "login" */ '../views/error/error'))

const routers = [
  {
    path: '/distribution/activity',
    name: '/distribution/activity',
    component: ActivityList,
    exact: true
  }, {
    path: '/system/role',
    name: '/system/role',
    component: Role,
    exact: true,
  }, {
    path: '/system/role/modify',
    name: '/system/role/modify',
    component: RoleModify,
    exact: true,
  }, {
    path: '/system/log',
    name: '/system/log',
    component: Log,
    exact: true
  }, {
    path: '/system/account',
    name: '/system/account',
    component: Account,
    exact: true
  }, {
    path: '/system/account/modify',
    name: '/system/account/modify',
    component: AccountModify,
    exact: true
  },
  //  {
  //   path: '/system/carriage',
  //   name: '/system/carriage',
  //   component: Carriage,
  //   exact: true
  // }, {
  //   path: '/system/carriage/modify',
  //   name: '/system/carriage/modify',
  //   component: CarriageModify,
  //   exact: true
  // },
   {
    path: '/system/menu',
    name: '/system/menu',
    component: Menu,
    exact: true
  }, {
    path: '/system/config',
    name: '/system/config',
    component: Config,
    exact: true
  }, {
    path: '/user/user',
    name: '/user/user',
    component: UserUser,
    exact: true
  }
  ,
  // {
  //   path: '/user/user/detail',
  //   name: '/user/user/detail',
  //   component: UserDetail,
  //   exact: true
  // }
  // , 
  // {
  //   path: '/user/user/balance',
  //   name: '/user/user/balance',
  //   component: UserBalance,
  //   exact: true
  // },{
  //   path: '/user/distributor',
  //   name: '/user/distributor',
  //   component: Distributor,
  //   exact: true
  // }
  // ,{
  //   path: '/user/distributor/detail',
  //   name: '/user/distributor/detail',
  //   component: UserDetail,
  //   exact: true
  // },
  // ,{
  //   path: '/user/distributor/balance',
  //   name: '/user/distributor/balance',
  //   component: UserBalance,
  //   exact: true
  // },
  // {
  //   path: '/goods/index',
  //   name: '/goods/index',
  //   component: GoodsIndex,
  //   exact: true
  // }, {
  //   path: '/goods/detail',
  //   name: '/goods/index/detail',
  //   component: GoodsDetail,
  //   exact: true
  // }, {
  //   path: '/goods/create',
  //   name: '/goods/index/create',
  //   component: GoodsCreate,
  //   exact: true
  // }, {
  //   path: '/goods/modify',
  //   name: '/goods/index/modify',
  //   component: GoodsModify,
  //   exact: true
  // }, {
  //   path: '/goods/brand',
  //   name: '/goods/brand',
  //   component: GoodsBrand,
  //   exact: true
  // }, {
  //   path: '/goods/brand/modify',
  //   name: '/goods/brand/modify',
  //   component: GoodsBrandModify,
  //   exact: true
  // }, {
  //   path: '/goods/classify',
  //   name: '/goods/classify',
  //   component: GoodsClassify,
  //   exact: true
  // }, {
  //   path: '/goods/classify/modify',
  //   name: '/goods/classify/modify',
  //   component: GoodsClassifyModify,
  //   exact: true
  // }, {
  //   path: '/goods/classify/final',
  //   name: '/goods/classify/final',
  //   component: GoodsClassifyFinal,
  //   exact: true
  // }, {
  //   path: '/goods/classify/final/modify',
  //   name: '/goods/classify/final/modify',
  //   component: GoodsClassifyFinalModify,
  //   exact: true
  // }, {
  //   path: '/goods/property',
  //   name: '/goods/property',
  //   component: GoodsProperty,
  //   exact: true
  // }, {
  //   path: '/goods/property/sell',
  //   name: '/goods/property/sell',
  //   component: GoodsPropertySell,
  //   exact: true
  // }, {
  //   path: '/goods/property/input',
  //   name: '/goods/property/input',
  //   component: GoodsPropertyInput,
  //   exact: true
  // }, {
  //   path: '/goods/group',
  //   name: '/goods/group',
  //   component: GoodsGroup,
  //   exact: true
  // }, {
  //   path: '/goods/group/modify',
  //   name: '/goods/group/modify',
  //   component: GoodsGroupModify,
  //   exact: true
  // }, {
  //   path: '/goods/customer',
  //   name: '/goods/customer',
  //   component: GoodsCustomer,
  //   exact: true
  // }, {
  //   path: '/goods/customer/modify',
  //   name: '/goods/customer/modify',
  //   component: GoodsCustomerModify,
  //   exact: true
  // }, {
  //   path: '/goods/supply',
  //   name: '/goods/supply',
  //   component: GoodsSupply,
  //   exact: true
  // }, {
  //   path: '/goods/supply/add',
  //   name: '/goods/supply/add',
  //   component: GoodsSupplyAdd,
  //   exact: true
  // }, {
  //   path: '/goods/supply/detail',
  //   name: '/goods/supply/detail',
  //   component: GoodsSupplyDetail,
  //   exact: true
  // }, {
  //   path: '/goods/items',
  //   name: '/goods/items',
  //   component: GoodsItems,
  //   exact: true
  // }, {
  //   path: '/goods/items/modify',
  //   name: '/goods/items/modify',
  //   component: GoodsItemsModify,
  //   exact: true
  // }, {
  //   path: '/operate/banner',
  //   name: '/operate/banner',
  //   component: OperateBanner,
  //   exact: true
  // }, {
  //   path: '/operate/banner/modify',
  //   name: '/operate/banner/modify',
  //   component: OperateBannerModify,
  //   exact: true
  // }, {
  //   path: '/operate/activity',
  //   name: '/operate/activity',
  //   component: OperateActivity,
  //   exact: true
  // }, {
  //   path: '/operate/activity/modify',
  //   name: '/operate/activity/modify',
  //   component: OperateActivityModify,
  //   exact: true
  // }, {
  //   path: '/operate/coupon',
  //   name: '/operate/coupon',
  //   component: OperateCounpon,
  //   exact: true
  // }, {
  //   path: '/operate/coupon/create',
  //   name: '/operate/coupon/create',
  //   component: OperateCounponCreate,
  //   exact: true
  // }, {
  //   path: '/operate/coupon/detail',
  //   name: '/operate/coupon/detail',
  //   component: OperateCounponDetail,
  //   exact: true
  // }, {
  //   path: '/operate/grant',
  //   name: '/operate/grant',
  //   component: OperateGrant,
  //   exact: true
  // }
  // , {
  //   path: '/order/index',
  //   name: '/order/index',
  //   component: Order,
  //   exact: true
  // }, {
  //   path: '/order/detail',
  //   name: '/order/index/detail',
  //   component: OrderDetail,
  //   exact: true
  // }, {
  //   path: '/order/cross',
  //   name: '/order/cross',
  //   component: OrderCross,
  //   exact: true
  // }, {
  //   path: '/order/cross/detail',
  //   name: '/order/cross/detail',
  //   component: OrderCrossDetail,
  //   exact: true
  // }, {
  //   path: '/order/service',
  //   name: '/order/service',
  //   component: OrderServe,
  //   exact: true
  // }, {
  //   path: '/order/service/detail',
  //   name: '/order/service/detail',
  //   component: OrderServeDetail,
  //   exact: true
  // }, {
  //   path: '/order/group',
  //   name: '/order/group',
  //   component: OrderGroup,
  //   exact: true
  // }, {
  //   path: '/order/ticket',
  //   name: '/order/ticket',
  //   component: OrderTicket,
  //   exact: true
  // }, {
  //   path: '/order/supply',
  //   name: '/order/supply',
  //   component: OrderSupply,
  //   exact: true
  // }, {
  //   path: '/order/supply/detail',
  //   name: '/order/supply/detail',
  //   component: OrderSupplyDetail,
  //   exact: true
  // }, {
  //   path: '/order/supplyService',
  //   name: '/order/supplyService',
  //   component: OrderSupplyService,
  //   exact: true
  // }, {
  //   path: '/order/supplyService/detail',
  //   name: '/order/supplyService/detail',
  //   component: OrderSupplyServiceDetail,
  //   exact: true
  // }, {
  //   path: '/order/crossServe',
  //   name: '/order/crossServe',
  //   component: OrderCrossServe,
  //   exact: true
  // }, {
  //   path: '/order/crossServe/detail',
  //   name: '/order/crossServe/detail',
  //   component: OrderCrossServeDetail,
  //   exact: true
  // }, {
  //   path: '/order/refund',
  //   name: '/order/refund',
  //   component: OrderRefund,
  //   exact: true
  // }, {
  //   path: '/order/pay',
  //   name: '/order/pay',
  //   component: OrderPay,
  //   exact: true
  // },
  //  {
  //   path: '/finance/account',
  //   name: '/finance/account',
  //   component: FinanceAccount,
  //   exact: true
  // }, {
  //   path: '/finance/account/detail',
  //   name: '/finance/account/detail',
  //   component: FinanceAccountDetail,
  //   exact: true
  // },
  //  {
  //   path: '/order/refund/detail',
  //   name: '/order/refund/detail',
  //   component: OrderRefundDetail,
  //   exact: true
  // },
  //  {
  //   path: '/finance/review',
  //   name: '/finance/review',
  //   component: FinanceReview,
  //   exact: true
  // }, {
  //   path: '/finance/review/detail',
  //   name: '/finance/review/detail',
  //   component: FinanceReviewDetail,
  //   exact: true
  // }, {
  //   path: '/finance/cash',
  //   name: '/finance/cash',
  //   component: FinanceCash,
  //   exact: true
  // }, {
  //   path: '/finance/cash/detail',
  //   name: '/finance/cash/detail',
  //   component: FinanceCashDetail,
  //   exact: true
  // }, {
  //   path: '/finance/goodsell',
  //   name: '/finance/goodsell',
  //   component: FinanceGoodSell,
  //   exact: true
  // }, {
  //   path: '/finance/balance',
  //   name: '/finance/balance',
  //   component: FinanceBalance,
  //   exact: true
  // }
  // ,{
  //   path: '/hospital/hospital',
  //   name: '/hospital/hospital',
  //   component: HospitalList,
  //   exact: true
  // },{
  //   path: '/hospital/hospital/modify',
  //   name: '/hospital/hospital/modify',
  //   component: HospitalListModify,
  //   exact: true
  // },
  {
    path: '/distribution/activity/modify',
    name: '/distribution/activity/modify',
    component: ActivityModify,
    exact: true
  },
  ,{
    path: '/distribution/activity/extension',
    name: '/distribution/activity/extension',
    component: ExtensionList,
    exact: true
  },
  {
    path: '/distribution/distributor',
    name: '/distribution/distributor',
    component: DistributorList,
    exact: true
  },
  {
    path: '/distribution/distributor/detail',
    name: '/distribution/distributor/detail',
    component: UserDetail,
    exact: true
  },
  {
    path: '/distribution/funds',
    name: '/distribution/funds',
    component: FundsManage,
    exact: true
  },
  {
    path: '/finace/commission',
    name: '/finace/commission',
    component: CommissionManage,
    exact: true
  },
  {
    path: '/distribution/order',
    name: '/distribution/order',
    component: DistributionOrderList,
    exact: true
  },
  {
    path: '/distribution/orderDetail',
    name: '/distribution/orderDetail',
    component: DistributionOrderDetail,
    exact: true
  },
  {
    path: '/error',
    name: '/error',
    component: ErrorIndex,
    exact: true
  }]
const exitRouters = [{
  path: '/',
  component: Login,
  exact: true,
}, {
  path: '/',
  component: Index,
}]

export {
  exitRouters, routers
};